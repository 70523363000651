import { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import styles from './Teachers.module.css';
import BackgroundObject from './BackgroundObject';
import FreeLessonPopup from './FreeLessonPopup';

export default function Teachers() {
    const { t, i18n } = useTranslation(); // Use the translation hook

    // Function to get the appropriate font class for the case based on the current language
    const getFontClass = () => {
        switch (i18n.language) {
            case 'ru':
                return styles.titleFontRussian;  // Russian font class
            case 'zh':
                return styles.titleFontChinese;  // Chinese font class
            default:
                return styles.titleFontEnglish;  // Default font class (English)
        }
    };

    const [showPopup, setShowPopup] = useState(false); // Add state to manage pop-up visibility

    const togglePopup = () => {
        setShowPopup(!showPopup); // Toggle pop-up visibility
    };

    // Teacher Switcher
    const teachers = {
        Chase: {
            id: 'Chase',
            name: t('teachers.teachersInfo.Chase.name'),
            bio: t('teachers.teachersInfo.Chase.bio'),
            regalia: t('teachers.teachersInfo.Chase.regalia', { returnObjects: true }),
            photo: '/assets/teachers/TeacherChase.png',
        },
        Male: {
            id: 'Male',
            name: t('teachers.teachersInfo.Male.name'),
            bio: t('teachers.teachersInfo.Male.bio'),
            regalia: t('teachers.teachersInfo.Male.regalia', { returnObjects: true }),
            photo: '/assets/teachers/Male.jpg',
        },

        Female: {
            id: 'Female',
            name: t('teachers.teachersInfo.Female.name'),
            bio: t('teachers.teachersInfo.Female.bio'),
            regalia: t('teachers.teachersInfo.Female.regalia', { returnObjects: true }),
            photo: '/assets/teachers/Female.jpg',
        },
    };

    const [currentTeacher, setCurrentTeacher] = useState(teachers.Chase);

    const handleTeacherSwitch = (event) => {
        setCurrentTeacher(teachers[event.target.id]);
    };

    return (
        <div className={styles.teachersSectionContainer}>
            <div className={styles.teachersSectionContent}>
                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectRings}
                        src={require('./assets/objects/rings.png')}
                        scrollFactor={-0.02}
                    />
                </div>

                <div className={styles.bgObjectWrapper}>
                    <BackgroundObject
                        className={styles.bgObjectEyescream}
                        src={require('./assets/objects/eyescreamOptimized.png')}
                        scrollFactor={-0.03}
                    />
                </div>

                <div className={styles.teachersWindow}>
                    <div className={styles.teachersWindowLeft}>
                        <h1 className={getFontClass()}>{t('teachers.sectionTitle')}</h1>
                        <button onClick={togglePopup}>{t('teachers.freeLessonButton')}</button>

                        <div className={styles.switchesWrapper}>
                            {Object.keys(teachers).map((teacherId) => (
                                <div
                                    key={teacherId}
                                    className={styles.switch}
                                    onClick={handleTeacherSwitch}
                                    id={teacherId}
                                    style={{
                                        backgroundImage: `url(${teachers[teacherId].photo})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        border:
                                            currentTeacher.id === teacherId ? '1.5px solid #F180EF' : 'none',
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.teachersWindowMiddle}>
                        <div
                            className={styles.teacherPhoto}
                            style={{
                                backgroundImage: `url(${currentTeacher.photo})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}
                        ></div>
                    </div>

                    <div className={styles.teachersWindowRight}>
                        <h2 className={styles.teacherName}>{currentTeacher.name}</h2>
                        <p className={styles.teacherBio}>{currentTeacher.bio}</p>
                        <div className={styles.teacherRegaliaWrapper}>
                            {currentTeacher.regalia.map((item, index) => (
                                <div key={index} className={styles.teacherRegalia}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <FreeLessonPopup showPopup={showPopup} togglePopup={togglePopup} />
        </div>
    );
}
